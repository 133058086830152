import React from 'react';
import { Link } from 'react-router-dom';
import InfoButton from './InfoButton';
import '../styles/HomePage.css';

const HomePage = () => {
  return (
    <div className="home-page">
      <h1>Interface Explorations</h1>
      <nav>
        <ul>
          <li><Link to="/unusual-experts">Unusual Experts</Link></li>
          <li><Link to="/honest-crm">Honest Personal CRM</Link></li>
        </ul>
      </nav>
      <InfoButton content="This site explores innovative interface designs in the age of AI." />
    </div>
  );
};

export default HomePage;