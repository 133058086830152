import { services } from '../data/services';

export const moveFaces = (faces) => {
  if (!faces || !Array.isArray(faces)) {
    console.error('Invalid faces array in moveFaces:', faces);
    return [];
  }

  return faces.map(face => {
    if (!face) {
      console.error('Invalid face object:', face);
      return null;
    }

    const speedFactor = 4 - face.popularity; // 1 moves slower, 3 moves faster
    const newX = face.position.x + face.velocity.x * speedFactor;
    const newY = face.position.y + face.velocity.y * speedFactor;

    // Bounce off the edges
    const bounceX = (newX <= 0 || newX >= window.innerWidth) ? -1 : 1;
    const bounceY = (newY <= 0 || newY >= window.innerHeight) ? -1 : 1;

    return {
      ...face,
      position: {
        x: Math.max(0, Math.min(newX, window.innerWidth)),
        y: Math.max(0, Math.min(newY, window.innerHeight))
      },
      velocity: {
        x: face.velocity.x * bounceX,
        y: face.velocity.y * bounceY
      }
    };
  }).filter(Boolean);
};

export const handleCollisions = (faces) => {
  if (!faces || !Array.isArray(faces)) {
    console.error('Invalid faces array in handleCollisions:', faces);
    return [];
  }

  const collidedFaces = new Set();

  for (let i = 0; i < faces.length; i++) {
    for (let j = i + 1; j < faces.length; j++) {
      const face1 = faces[i];
      const face2 = faces[j];

      if (face1 && face2 && checkCollision(face1, face2)) {
        // Swap velocities for a simple collision effect
        const temp = face1.velocity;
        face1.velocity = face2.velocity;
        face2.velocity = temp;

        // Handle popularity-based disappearance
        if (face1.popularity < face2.popularity) {
          collidedFaces.add(face1.id);
        } else if (face1.popularity > face2.popularity) {
          collidedFaces.add(face2.id);
        }
      }
    }
  }

  // Remove faces that should disappear and replace them
  const remainingFaces = faces.filter(face => !collidedFaces.has(face.id));
  const newFaces = generateNewFaces(faces.length - remainingFaces.length);

  return [...remainingFaces, ...newFaces];
};

const checkCollision = (face1, face2) => {
  const distance = Math.sqrt(
    Math.pow(face1.position.x - face2.position.x, 2) +
    Math.pow(face1.position.y - face2.position.y, 2)
  );
  return distance < 50; // Adjust this value based on your face size
};

const generateNewFaces = (count) => {
  if (!services || !Array.isArray(services) || services.length === 0) {
    console.error('Invalid services array:', services);
    return [];
  }

  return Array(count).fill().map(() => {
    const randomService = services[Math.floor(Math.random() * services.length)];
    return {
      ...randomService,
      position: { x: Math.random() * window.innerWidth, y: Math.random() * window.innerHeight },
      velocity: { x: (Math.random() - 0.5) * 2, y: (Math.random() - 0.5) * 2 },
      // popularity is already included in the randomService data
    };
  });
};