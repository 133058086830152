import React, { useState, useEffect } from 'react';
import Face from './Face';
import MiniScreen from './MiniScreen';
import InfoButton from './InfoButton';  
import { moveFaces, handleCollisions } from '../utils/movement';
import { services } from '../data/services';
import '../styles/FloatingFaces.css';

const FloatingFaces = () => {
  const [faces, setFaces] = useState([]);
  const [selectedFace, setSelectedFace] = useState(null);

  useEffect(() => {
    const getRandomFaces = () => {
      const shuffled = [...services].sort(() => 0.5 - Math.random());
      return shuffled.slice(0, 10).map((service) => ({
        ...service,
        position: { x: Math.random() * window.innerWidth, y: Math.random() * window.innerHeight },
        velocity: { x: (Math.random() - 0.5) * 2, y: (Math.random() - 0.5) * 2 },
        // popularity is now coming from the service data, no need to assign it here
      }));
    };

    setFaces(getRandomFaces());

    const movementInterval = setInterval(() => {
      setFaces(prevFaces => {
        const movedFaces = moveFaces(prevFaces);
        return handleCollisions(movedFaces);
      });
    }, 50);

    return () => clearInterval(movementInterval);
  }, []);

  const handleFaceClick = (face) => {
    setSelectedFace(face);
  };

  const closeMiniScreen = () => {
    setSelectedFace(null);
  };

  const handleAddYourself = () => {
    window.open('https://tally.so/r/waG2eb', '_blank');
  };

  return (
    <div className="floating-faces-container">
         <InfoButton />  {/* Add this line */}
   {faces.map(face => (
        <Face
          key={face.id}
          face={face}
          onClick={() => handleFaceClick(face)}
        />
      ))}
      {selectedFace && (
        <MiniScreen
          face={selectedFace}
          onClose={closeMiniScreen}
        />
      )}
      <button className="add-yourself-button" onClick={handleAddYourself}>
        Add yourself
      </button>
    </div>
  );
};

export default FloatingFaces;