import React, { useState, useEffect } from 'react';
import Statistics from './Statistics';
import '../styles/HonestPersonalCRM.css';

const HonestPersonalCRM = () => {
  const [friends, setFriends] = useState([
    { id: 1, name: 'Alice', daysSinceContact: 5, image: 'https://i.pravatar.cc/150?img=1' },
    { id: 2, name: 'Bob', daysSinceContact: 45, image: 'https://i.pravatar.cc/150?img=2' },
    { id: 3, name: 'Charlie', daysSinceContact: 200, image: 'https://i.pravatar.cc/150?img=3' },
  ]);

  const [newFriend, setNewFriend] = useState({ name: '', daysSinceContact: 0, image: '' });
  const [showAddForm, setShowAddForm] = useState(false);
  const [editingFriend, setEditingFriend] = useState(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setFriends(friends.map(friend => ({
        ...friend,
        daysSinceContact: friend.daysSinceContact + 1
      })));
    }, 60000);

    return () => clearInterval(timer);
  }, [friends]);

  const getSection = (days) => {
    if (days <= 30) return 'heaven';
    if (days <= 180) return 'purgatory';
    return 'wasteland';
  };

  const addFriend = (e) => {
    e.preventDefault();
    const newFriendData = {
      ...newFriend,
      id: Date.now(),
      image: newFriend.image || `https://i.pravatar.cc/150?img=${Math.floor(Math.random() * 70) + 1}`
    };
    setFriends([...friends, newFriendData]);
    setNewFriend({ name: '', daysSinceContact: 0, image: '' });
    setShowAddForm(false);
  };
  
  const editFriend = (id) => {
    const friend = friends.find(f => f.id === id);
    setEditingFriend(friend);
    setShowAddForm(true);
  };
  
  const updateFriend = (e) => {
    e.preventDefault();
    setFriends(friends.map(friend => 
      friend.id === editingFriend.id ? editingFriend : friend
    ));
    setEditingFriend(null);
    setShowAddForm(false);
  };

  const resetContact = (id) => {
    setFriends(friends.map(friend => 
      friend.id === id ? {...friend, daysSinceContact: 0} : friend
    ));
  };

  const cloudUrl = "https://www.textures4photoshop.com/tex/thumbs/blue-cloud-PNG-transparent-thumb26.png";
  const cactusUrl = "https://www.freeiconspng.com/thumbs/cactus-png/cactus-png-32.png";
  const armadilloUrl = "https://cdn.pixabay.com/photo/2020/08/18/16/51/armadillo-5498739_1280.png";

  const renderClouds = () => {
    const clouds = [];
    for (let i = 0; i < 5; i++) {
      const style = {
        left: `${Math.random() * 100}%`,
        top: `${Math.random() * 60}%`,
        animationDuration: `${Math.random() * 60 + 60}s`,
        animationDelay: `${Math.random() * 30}s`,
      };
      clouds.push(
        <img 
          key={i} 
          src={cloudUrl} 
          alt="cloud" 
          className="cloud" 
          style={style}
        />
      );
    }
    return clouds;
  };

  const renderDesertItems = () => {
    const items = [];
    for (let i = 0; i < 3; i++) {
      items.push(
        <img 
          key={`cactus-${i}`} 
          src={cactusUrl} 
          alt="cactus" 
          className="desert-item cactus" 
          style={{left: `${Math.random() * 90}%`}}
        />
      );
    }
    for (let i = 0; i < 2; i++) {
      items.push(
        <img 
          key={`armadillo-${i}`} 
          src={armadilloUrl} 
          alt="armadillo" 
          className="desert-item armadillo" 
          style={{left: `${Math.random() * 90}%`}}
        />
      );
    }
    return items;
  };

  const DayScale = ({ max }) => (
    <div className="day-scale">
      {[0, max/4, max/2, 3*max/4, max].map(day => (
        <div key={day} className="scale-mark">
          <span>{Math.round(day)}</span>
        </div>
      ))}
    </div>
  );

  return (
    <div className="honest-crm">
      <Statistics friends={friends} />
      <div className="section heaven">
        <DayScale max={30} />
        <h2>Friend Heaven</h2>
        {renderClouds()}
        {friends.filter(f => getSection(f.daysSinceContact) === 'heaven').map(friend => (
          <FriendFace key={friend.id} friend={friend} onUpdate={resetContact} onEdit={editFriend} />
        ))}
      </div>
      <div className="section purgatory">
        <DayScale max={180} />
        <h2>Purgatory</h2>
        {friends.filter(f => getSection(f.daysSinceContact) === 'purgatory').map(friend => (
          <FriendFace key={friend.id} friend={friend} onUpdate={resetContact} onEdit={editFriend} />
        ))}
      </div>
      <div className="section wasteland">
        <DayScale max={365} />
        <h2>Wasteland of Forgotten Friendship</h2>
        {renderDesertItems()}
        {friends.filter(f => getSection(f.daysSinceContact) === 'wasteland').map(friend => (
          <FriendFace key={friend.id} friend={friend} onUpdate={resetContact} onEdit={editFriend} />
        ))}
      </div>
      <button className="add-friend-button" onClick={() => setShowAddForm(!showAddForm)}>+</button>
      {showAddForm && (
        <form onSubmit={editingFriend ? updateFriend : addFriend} className="add-friend-form">
          <input
            type="text"
            value={editingFriend ? editingFriend.name : newFriend.name}
            onChange={(e) => editingFriend 
              ? setEditingFriend({...editingFriend, name: e.target.value})
              : setNewFriend({...newFriend, name: e.target.value})}
            placeholder="Friend's name"
            required
          />
          <input
            type="number"
            value={editingFriend ? editingFriend.daysSinceContact : newFriend.daysSinceContact}
            onChange={(e) => editingFriend
              ? setEditingFriend({...editingFriend, daysSinceContact: parseInt(e.target.value)})
              : setNewFriend({...newFriend, daysSinceContact: parseInt(e.target.value)})}
            placeholder="Days since contact"
            required
          />
          <input
            type="text"
            value={editingFriend ? editingFriend.image : newFriend.image}
            onChange={(e) => editingFriend
              ? setEditingFriend({...editingFriend, image: e.target.value})
              : setNewFriend({...newFriend, image: e.target.value})}
            placeholder="Image URL (optional)"
          />
          <button type="submit">{editingFriend ? 'Update Friend' : 'Add Friend'}</button>
          {editingFriend && (
            <button type="button" onClick={() => {
              setEditingFriend(null);
              setShowAddForm(false);
            }}>Cancel</button>
          )}
        </form>
      )}
    </div>
  );
};

const FriendFace = ({ friend, onUpdate, onEdit }) => {
  return (
    <div 
      className="friend-face" 
      style={{
        left: `${Math.random() * 80}%`,
        top: `${(friend.daysSinceContact % 60) / 60 * 100}%`
      }}
      onClick={() => onUpdate(friend.id)}
    >
      <img src={friend.image} alt={friend.name} />
      <div className="friend-info">
        <p>{friend.name}</p>
        <p>{friend.daysSinceContact} days since we talked</p>
        <button onClick={(e) => {
          e.stopPropagation();
          onEdit(friend.id);
        }}>Edit</button>
      </div>
    </div>
  );
};

export default HonestPersonalCRM;