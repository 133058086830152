import React from 'react';
import '../styles/MiniScreen.css';

const MiniScreen = ({ face, onClose }) => {
  return (
    <div className="mini-screen">
      <button className="close-button" onClick={onClose}>X</button>
      <h2>{face.name}</h2>
      <img src={face.image} alt={face.name} />
      <p>{face.fullDescription}</p>
      <a href={face.link} target="_blank" rel="noopener noreferrer" className="learn-more-button">
        Learn More
      </a>
    </div>
  );
};

export default MiniScreen;