import React, { useState } from 'react';
import '../styles/InfoButton.css';

const InfoButton = ({ content }) => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="info-button-container">
      <button 
        className="info-button" 
        onMouseEnter={() => setShowInfo(true)}
        onMouseLeave={() => setShowInfo(false)}
      >
        ?
      </button>
      {showInfo && (
        <div className="info-box">
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

export default InfoButton;