import React from 'react';
import '../styles/Face.css';

const Face = ({ face, onClick }) => {
  return (
    <div
      className="face"
      style={{
        left: `${face.position.x}px`,
        top: `${face.position.y}px`,
      }}
      onClick={onClick}
    >
      <img src={face.image} alt={face.name} />
      <span className="face-description">{face.description}</span>
    </div>
  );
};

export default Face;