import React from 'react';

const Statistics = ({ friends }) => {
  const totalFriends = friends.length;
  const heavenFriends = friends.filter(f => f.daysSinceContact <= 30).length;
  const purgatoryFriends = friends.filter(f => f.daysSinceContact > 30 && f.daysSinceContact <= 180).length;
  const wastelandFriends = friends.filter(f => f.daysSinceContact > 180).length;

  const averageContactDays = Math.round(
    friends.reduce((sum, friend) => sum + friend.daysSinceContact, 0) / totalFriends
  );

  return (
    <div className="statistics">
      <h3>Friendship Statistics</h3>
      <ul>
        <li>Total Friends: {totalFriends}</li>
        <li>Friends in Heaven: {heavenFriends}</li>
        <li>Friends in Purgatory: {purgatoryFriends}</li>
        <li>Friends in Wasteland: {wastelandFriends}</li>
        <li>Average Days Since Contact: {averageContactDays}</li>
      </ul>
    </div>
  );
};

export default Statistics;