export const services = [
  {
    id: 1,
    name: "Sarah Chen",
    description: "AI Ethics Consultant",
    fullDescription: "With a Ph.D. in Computer Science and a background in philosophy, Sarah specializes in developing ethical frameworks for AI systems. She has consulted for major tech companies and government agencies, ensuring AI implementations align with human values and societal norms.",
    image: "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&w=800",
    link: "https://example.com/sarah-chen",
    popularity: 1
  },
  {
    id: 2,
    name: "Markus Williams",
    description: "Urban Vertical Farming Expert",
    fullDescription: "Marcus combines his expertise in agriculture and architectural design to create efficient vertical farming solutions for urban environments. He has successfully implemented projects in major cities worldwide, increasing local food production and sustainability.",
    image: "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?auto=compress&cs=tinysrgb&w=800",
    link: "https://example.com/marcus-williams",
    popularity: 2
  },
  {
    id: 3,
    name: "Elena Rodriguez",
    description: "Quantum Computing Educator",
    fullDescription: "Elena simplifies complex quantum computing concepts for businesses and individuals. With a background in physics and education, she has developed online courses and workshops that have helped thousands understand the potential of quantum technology.",
    image: "https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&w=800",
    link: "https://example.com/elena-rodriguez",
    popularity: 2
  },
  {
    id: 4,
    name: "Jamal Hassan",
    description: "Drone Choreographer",
    fullDescription: "Jamal creates stunning aerial displays using swarms of drones. His background in computer science and visual arts allows him to program intricate flight patterns for events, advertisements, and artistic performances.",
    image: "https://example.com/jamal-hassan.jpg",
    link: "https://example.com/jamal-hassan",
    popularity: 3
  },
  {
    id: 5,
    name: "Olivia Barnes",
    description: "Digital Detox Consultant",
    fullDescription: "Olivia helps individuals and organizations find a healthy balance with technology. Her programs combine psychology, mindfulness techniques, and practical strategies to reduce digital overwhelm and increase productivity and well-being.",
    image: "https://images.pexels.com/photos/948873/pexels-photo-948873.jpeg?auto=compress&cs=tinysrgb&w=800",
    link: "https://example.com/olivia-barnes",
    popularity: 1
  },
  {
    id: 6,
    name: "Hiroshi Tanaka",
    description: "Biomimicry Innovation Specialist",
    fullDescription: "Hiroshi applies nature's time-tested patterns and strategies to human design challenges. His work has led to breakthroughs in sustainable architecture, efficient transportation systems, and resilient materials inspired by biological structures.",
    image: "https://example.com/hiroshi-tanaka.jpg",
    link: "https://example.com/hiroshi-tanaka",
    popularity: 2
  },
  {
    id: 7,
    name: "Zoe Fletcher",
    description: "Space Tourism Experience Designer",
    fullDescription: "Zoe develops immersive experiences for the emerging space tourism industry. Her background in aerospace engineering and virtual reality allows her to create realistic simulations and training programs for future civilian space travelers.",
    image: "https://images.pexels.com/photos/8154451/pexels-photo-8154451.jpeg?auto=compress&cs=tinysrgb&w=800",
    link: "https://example.com/zoe-fletcher",
    popularity: 3
  },
  {
    id: 8,
    name: "Aiden O'Connor",
    description: "Synthetic Biology Consultant",
    fullDescription: "Aiden advises companies on the application of synthetic biology in various industries. His expertise helps clients develop new biofuels, create sustainable materials, and innovate in medicine and agriculture using engineered biological systems.",
    image: "https://example.com/aiden-oconnor.jpg",
    link: "https://example.com/aiden-oconnor",
    popularity: 2
  },
  {
    id: 9,
    name: "Lila Patel",
    description: "Haptic Technology Specialist",
    fullDescription: "Lila designs touch-based interfaces for virtual and augmented reality applications. Her innovations have enhanced remote surgery systems, improved accessibility for visually impaired users, and created more immersive gaming experiences.",
    image: "https://images.pexels.com/photos/1239288/pexels-photo-1239288.jpeg?auto=compress&cs=tinysrgb&w=800",
    link: "https://example.com/lila-patel",
    popularity: 2
  },
  {
    id: 10,
    name: "Felix Kruger",
    description: "Culinary Alchemist",
    fullDescription: "Felix combines molecular gastronomy with traditional cooking techniques to create unique dining experiences. His expertise in food science and culinary arts has led to collaborations with top restaurants and food manufacturers worldwide.",
    image: "https://images.pexels.com/photos/262391/pexels-photo-262391.jpeg?auto=compress&cs=tinysrgb&w=800",
    link: "https://example.com/felix-kruger",
    popularity: 1
  },
  {
    id: 11,
    name: "Amara Okafor",
    description: "Afrofuturism Art Curator",
    fullDescription: "Amara specializes in curating exhibitions that explore African and African diaspora perspectives on technology, science fiction, and future societies. Her work bridges traditional and digital art forms, showcasing emerging artists and challenging conventional narratives.",
    image: "https://images.pexels.com/photos/7562191/pexels-photo-7562191.jpeg?auto=compress&cs=tinysrgb&w=800",
    link: "https://example.com/amara-okafor",
    popularity: 2
  },
  {
    id: 12,
    name: "Lars Svensson",
    description: "Cryonics Research Coordinator",
    fullDescription: "Lars manages research projects aimed at improving long-term tissue preservation techniques. His work involves coordinating multidisciplinary teams of biologists, engineers, and medical professionals to advance the field of cryonics and potential future revival technologies.",
    image: "https://images.pexels.com/photos/2128807/pexels-photo-2128807.jpeg?auto=compress&cs=tinysrgb&w=800",
    link: "https://example.com/lars-svensson",
    popularity: 3
  },
  {
    id: 13,
    name: "Yuki Nakamura",
    description: "Holographic Interface Designer",
    fullDescription: "Yuki creates intuitive and engaging holographic user interfaces for various applications. Her designs have been implemented in medical imaging systems, educational tools, and next-generation smartphones, pushing the boundaries of how we interact with digital information.",
    image: "https://example.com/yuki-nakamura.jpg",
    link: "https://example.com/yuki-nakamura",
    popularity: 2
  },
  {
    id: 14,
    name: "Carlos Mendoza",
    description: "Neuromarketing Strategist",
    fullDescription: "Carlos applies neuroscience and psychology to develop effective marketing strategies. His data-driven approach helps companies create more engaging advertisements, optimize user experiences, and build stronger emotional connections with their target audiences.",
    image: "https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=800",
    link: "https://example.com/carlos-mendoza",
    popularity: 1
  },
  {
    id: 15,
    name: "Ingrid Bergstrom",
    description: "Autonomous Vehicle Ethics Programmer",
    fullDescription: "Ingrid develops decision-making algorithms for self-driving cars, focusing on ethical considerations in potential accident scenarios. Her work involves collaborating with philosophers, policymakers, and engineers to create responsible AI systems for transportation.",
    image: "https://example.com/ingrid-bergstrom.jpg",
    link: "https://example.com/ingrid-bergstrom",
    popularity: 2
  },
  {
    id: 16,
    name: "Raj Gupta",
    description: "Blockchain Governance Architect",
    fullDescription: "Raj designs governance systems for decentralized organizations and blockchain networks. His expertise in cryptoeconomics and game theory helps create robust, fair, and efficient decision-making processes for digital communities and cryptocurrency projects.",
    image: "https://images.pexels.com/photos/21820981/pexels-photo-21820981/free-photo-of-portrait-of-brunette-man-in-eyeglasses-in-istanbul.jpeg?auto=compress&cs=tinysrgb&w=800",
    link: "https://example.com/raj-gupta",
    popularity: 2
  },
  {
    id: 17,
    name: "Sophia Papadopoulos",
    description: "Xenolinguistics Researcher",
    fullDescription: "Sophia studies potential communication systems for extraterrestrial intelligence. Her work involves developing algorithms for decoding unknown languages, creating universal communication protocols, and advising on SETI (Search for Extraterrestrial Intelligence) projects.",
    image: "https://example.com/sophia-papadopoulos.jpg",
    link: "https://example.com/sophia-papadopoulos",
    popularity: 3
  },
  {
    id: 18,
    name: "Kwame Osei",
    description: "Regenerative Economics Consultant",
    fullDescription: "Kwame helps businesses and governments transition to regenerative economic models that restore and enhance natural ecosystems. His expertise in ecological economics and systems thinking guides the development of sustainable and resilient economic practices.",
    image: "https://example.com/kwame-osei.jpg",
    link: "https://example.com/kwame-osei",
    popularity: 1
  },
  {
    id: 19,
    name: "Isabella Rossi",
    description: "Robotic Swarm Choreographer",
    fullDescription: "Isabella programs coordinated movements for swarms of small robots, creating mesmerizing displays and efficient problem-solving systems. Her work spans entertainment, search and rescue operations, and environmental monitoring applications.",
    image: "https://example.com/isabella-rossi.jpg",
    link: "https://example.com/isabella-rossi",
    popularity: 2
  },
  {
    id: 20,
    name: "Theo van der Meer",
    description: "Quantum Cryptography Implementer",
    fullDescription: "Theo specializes in implementing quantum cryptography systems for ultra-secure communications. His expertise helps financial institutions, government agencies, and tech companies protect sensitive data against current and future cyber threats, including potential quantum computer attacks.",
    image: "https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg?auto=compress&cs=tinysrgb&w=800",
    link: "https://example.com/theo-vandermeer",
    popularity: 2
  }
];